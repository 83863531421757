import { React, useState, useEffect } from "react";
import { Grid, Select, MenuItem } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import Cart from "./Cart";
import NewNav from "./NewNav";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";
import Snowfall from "react-snowfall";
import heartImg from "../images/heartpng.png";
import Countdown from "react-countdown";

const Renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <span>
      <div className="countdown-container">
        <h1>
          {days}d {hours}h {minutes}m {seconds}s
        </h1>
      </div>
    </span>
  );
};

const sortOptions = ["High to Low", "Low to High"];


const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const handleOptionChange = (event) => {
  console.log("changed");
};

const LatestRelease = (props) => {
  const { products } = useShopify();
  const [latestEnabled, setLatestEnabled] = useState(true);
  const width = window.innerWidth;
  const [isSnow, setIsSnow] = useState(true);
  const [displayHearts, setDisplayHearts] = useState(true);

  const displayLatest = () => {
    setLatestEnabled(true);
  };

  const productFunctionsArr = [displayLatest];

  const banners = ["/./Assets/lootdropcover.jpg"];

  const heartImage = document.createElement("img");
  heartImage.src = heartImg;
  const images = [heartImage];

  return (
    <>
      <NewNav props={productFunctionsArr} />

      <div className="App__header_latest">
        <Carousel
          className="App__carousel"
          autoPlay
          infiniteLoop
          interval={7000}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          dynamicHeight={true}
        >
          {banners.map((banner, i) => (
            <img key={i} src={banner} alt={`banner ${i}`} />
          ))}
        </Carousel>
      </div>
      

      <div className="App__clothing-title">
        <h1 className="App__clothing-text">Latest Releases</h1>
      </div>


      <div className="Products-wrapper_latest">
        <Grid container spacing={2}>
          <Grid item xs={false} md={1} xl={2} />
          <Grid item xs={12} md={10} xl={8}>
            <div className="Products-wrapper__inner">
              <Grid container spacing={3}>
                {products && products.length > 0 ? (
                  products
                    .filter(function (product) {
                      for (var latest in products) {
                        if (
                          product.handle.includes("large-monke-plush") ||
                          product.handle.includes("extra-large-monke-plush") ||
                          product.handle.includes("small-monke-plush") ||
                          product.title.includes(
                            "Basquiat Monke III"
                          ) ||
                          product.title.includes("Basquiat II") ||
                          product.title.includes("Vintage") ||
                          product.title.includes("Rug") ||
                          product.title.includes("Necklace") ||
                          product.title.includes("Bobble") ||
                          product.title.includes("Spooner") ||
                          product.title.includes("Raining") ||
                          product.title.includes("Enjoying Banana Dad Hat") ||
                          product.title.includes("Enjoying Banana Tote") ||
                          product.title.includes("Embroidered Monke Socks (White)") ||
                          product.title.includes("Embroidered Swim Trunks") ||
                          product.title.includes("Virtual Monke") ||
                          product.title.includes("Monke Plushie Keychain") ||
                          product.title.includes("Battlefield") ||
                          product.title.includes("Double Monke") ||
                          product.title.includes("Monke vs Brinda T-Shirt") ||
                          product.title.includes("Great Banana Race") ||
                          product.title.includes("Blueprint") ||
                          product.title.includes("Kick Flip Case") ||
                          product.title.includes("Swing Phone") ||
                          product.title.includes("Pattern Black Case") ||
                          product.title.includes("Monke Tank Top") ||
                          product.title.includes("Sweat Shorts") ||
                          product.title.includes("Murder") ||
                          product.title.includes("Detective") ||
                          product.title.includes("Pumpkin") ||
                          product.title.includes("Sweatpants") ||
                          product.title === ("Enamel Pin") ||
                          product.title === ("Enamel Keychain") ||
                          product.title.includes("Banana Bag") ||
                          product.title.includes("Rust Monke") ||
                          product.title === ("Monke Embroidered T-Shirt") ||
                          product.handle === "enamel-pin" ||
                          product.handle === "enamel-keychain" ||
                          product.handle === "monke-logo-embroidered-hoodie" ||
                          product.title === "Brinda"
                        ) {
                          return latest;
                        }
                      }
                    })
                    .map((latest, i) => (
                      <Grid item xs={6} md={4} lg={3} key={i}>
                        <Product product={latest} history={props.history} />
                      </Grid>
                    ))
                ) : (
                  <Placeholder />
                )}
              </Grid>
            </div>
            <Grid item xs={false} md={2} />
          </Grid>
        </Grid>
      </div>
      <Cart />
      <Footer />
    </>
  );
};

export default LatestRelease;

